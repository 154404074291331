

























@import "../assets/scss/colors";

.alerte-container {
    position: absolute;
    top: 5px;
    min-height: 50px;
    width: calc(100% - 42px);
    border: 1px solid black;
    border-radius: 2px;
    padding: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .fermer-icone {
        position: absolute;
        right: 7px;
        top: 7px;
        font-size: .8rem;
        cursor: pointer;
    }

    &.alerte-info {
        border-color: $primary;
        background: lighten($primary, 35%);
        color: $primary;

        .fermer-icone {
            color: $primary
        }
    }

    &.alerte-erreur {
        border-color: $error;
        background: lighten($error, 35%);
        color: $error;

        .fermer-icone {
            color: $error
        }
    }

    &.alerte-success {
        border-color: $success;
        background: lighten($success, 35%);
        color: $success;

        .fermer-icone {
            color: $success
        }
    }
}
