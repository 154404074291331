
























































































































































@import "../assets/scss/colors";

.cadre {
    height: 100%;
}

.cadre-tete {
    border-bottom: 1px solid $gray-light;
}

.cadre-content {
    padding: 15px 0 15px 15px;
    overflow-y: scroll;
    position: relative;
}

.dossier-nom {
    display: flex;
    align-items: center;
    width: 100%;

    i {
        margin-right: 5px;
    }

    span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.etapes--completees {
    margin-top: 60px !important;
}

