.edit-icon[data-v-541ab34e] {
  margin-right: 0;
  margin-left: auto;
}

i.far[data-v-5e8d6860] {
  margin-right: 7px;
}

.edit-icon[data-v-8cd76f60] {
  margin-right: 0;
  margin-left: auto;
}

.edit-icon[data-v-1fb26697] {
  margin-right: 0;
  margin-left: auto;
}

.edit-icon[data-v-d97b9c80] {
  margin-right: 0;
  margin-left: auto;
}

.edit-icon[data-v-84adcbfa] {
  margin-right: 0;
  margin-left: auto;
}

.edit-icon[data-v-a6ecfd68] {
  margin-right: 0;
  margin-left: auto;
}

.avatar-icon[data-v-3ccbeff6] {
  margin-right: 10px;
  font-size: 30px;
}
.edit-icon[data-v-3ccbeff6] {
  margin-right: 0;
  margin-left: auto;
}

.modal .btn-pas[data-v-6121dfea] {
  margin-left: auto;
  margin-right: 0;
}
.modal .modal-content[data-v-6121dfea] {
  width: calc(100% - 60px) !important;
}
.modal .cadre-content[data-v-6121dfea] {
  display: flex;
  height: calc(100vh - 60px - 60px - 60px);
}
.modal .cadre-content > div[data-v-6121dfea] {
    width: 50%;
}
.modal .cadre-content > div[data-v-6121dfea]:first-child {
      margin-right: 40px;
}
.modal .long-checkbox[data-v-6121dfea] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal .long-checkbox > div[data-v-6121dfea] {
    width: 80%;
}

.edit-icon[data-v-7fb4af1e] {
  margin-right: 0;
  margin-left: auto;
}

.cadre-conteneur[data-v-49e28d88] {
  height: 100%;
}
.cadre-conteneur .cadre[data-v-49e28d88] {
    background: #fff;
}
.nouveau-dossier[data-v-49e28d88] {
  max-width: 500px;
  height: 100%;
  margin: auto;
}
.nouveau-dossier .cadre-pied[data-v-49e28d88] {
    justify-content: space-between;
}
.nouveau-dossier .create-btn[data-v-49e28d88] {
    width: 100%;
}

.edit-icon[data-v-c9f8a996] {
  margin-right: 0;
  margin-left: auto;
}

.cadre-conteneur[data-v-4edf2aa8] {
  height: 100%;
}
.cadre-conteneur .cadre[data-v-4edf2aa8] {
    background: #fff;
}
.nouveau-dossier[data-v-4edf2aa8] {
  max-width: 500px;
  height: 100%;
  margin: auto;
}
.nouveau-dossier .cadre-pied[data-v-4edf2aa8] {
    justify-content: space-between;
}
.nouveau-dossier .create-btn[data-v-4edf2aa8] {
    width: 100%;
}

.edit-icon[data-v-43782b2b] {
  margin-right: 0;
  margin-left: auto;
}

.edit-icon[data-v-71183132] {
  margin-right: 0;
  margin-left: auto;
}

.dropdown-container {
  -ms-user-select: none;
      user-select: none;
  min-width: 300px;
  background: white;
  z-index: 10;
  border-radius: 3px;
  position: absolute;
  display: none;
  right: -10px;
  top: 25px;
  border: 1px solid #E7E7E7;
}
.dropdown-container .cadre-tete {
    padding: 20px;
}
.dropdown-container::after {
    content: '';
    position: absolute;
    top: -6px;
    background: white;
    height: 10px;
    width: 10px;
    right: 10px;
    transform: rotate(45deg);
    border-left: 1px solid #E7E7E7;
    border-top: 1px solid #E7E7E7;
    z-index: -1;
}
.dropdown-container.opened {
    display: block;
}
.dropdown-container .dropdown-element {
    cursor: pointer;
    padding: 10px;
}
.dropdown-container .dropdown-element:hover {
      color: #105BFB;
}
.dropdown-container .cadre {
    border: none;
    height: 100%;
}
.dropdown-container .cadre-tete {
    color: #105BFB;
    border-bottom: 1px solid #105BFB;
}

.alerte-container[data-v-eb228072] {
  position: absolute;
  top: 5px;
  min-height: 50px;
  width: calc(100% - 42px);
  border: 1px solid black;
  border-radius: 2px;
  padding: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alerte-container .fermer-icone[data-v-eb228072] {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: .8rem;
    cursor: pointer;
}
.alerte-container.alerte-info[data-v-eb228072] {
    border-color: #105BFB;
    background: #c0d3fe;
    color: #105BFB;
}
.alerte-container.alerte-info .fermer-icone[data-v-eb228072] {
      color: #105BFB;
}
.alerte-container.alerte-erreur[data-v-eb228072] {
    border-color: #E62565;
    background: #f8c5d6;
    color: #E62565;
}
.alerte-container.alerte-erreur .fermer-icone[data-v-eb228072] {
      color: #E62565;
}
.alerte-container.alerte-success[data-v-eb228072] {
    border-color: #159588;
    background: #71ebdf;
    color: #159588;
}
.alerte-container.alerte-success .fermer-icone[data-v-eb228072] {
      color: #159588;
}

.cadre[data-v-15e6cace] {
  height: 100%;
}
.cadre-tete[data-v-15e6cace] {
  border-bottom: 1px solid #E7E7E7;
}
.cadre-content[data-v-15e6cace] {
  padding: 15px 0 15px 15px;
  overflow-y: scroll;
  position: relative;
}
.dossier-nom[data-v-15e6cace] {
  display: flex;
  align-items: center;
  width: 100%;
}
.dossier-nom i[data-v-15e6cace] {
    margin-right: 5px;
}
.dossier-nom span[data-v-15e6cace] {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.etapes--completees[data-v-15e6cace] {
  margin-top: 60px !important;
}

.modal-ptz .btn-ptz[data-v-1c6f7c9f] {
  margin-left: auto;
  margin-right: 0;
}
.modal-ptz .modal-content[data-v-1c6f7c9f] {
  width: calc(100% - 60px) !important;
}
.modal-ptz .cadre-content[data-v-1c6f7c9f] {
  display: flex;
  height: calc(100vh - 60px - 60px - 60px);
}
.modal-ptz .cadre-content > div[data-v-1c6f7c9f] {
    width: 50%;
}
.modal-ptz .cadre-content > div[data-v-1c6f7c9f]:first-child {
      margin-right: 40px;
}
.modal-ptz .long-checkbox[data-v-1c6f7c9f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-ptz .long-checkbox > div[data-v-1c6f7c9f] {
    width: 80%;
}

.modal .btn-pas[data-v-9f0dd07c] {
  margin-left: auto;
  margin-right: 0;
}
.modal .cadre-content[data-v-9f0dd07c] {
  height: calc(100vh - 60px - 60px - 60px);
}
.modal .long-checkbox[data-v-9f0dd07c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal .long-checkbox > div[data-v-9f0dd07c] {
    width: 80%;
}

.modal-ptz .btn-ptz[data-v-3d786f79] {
  margin-left: auto;
  margin-right: 0;
}
.modal-ptz .cadre-content[data-v-3d786f79] {
  display: flex;
  height: calc(100vh - 60px - 60px - 60px);
}
.modal-ptz .long-checkbox[data-v-3d786f79] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-ptz .long-checkbox > div[data-v-3d786f79] {
    width: 80%;
}

.btn-large[data-v-641e86a8] {
  margin-bottom: 10px;
}

.chargement[data-v-f7997c7a] {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 9999;
}
.chargement div[data-v-f7997c7a] {
    position: absolute;
    color: #105BFB;
    left: 50%;
    top: 50%;
    font-size: 20px;
    margin-left: -120px;
}
@media screen and (max-width: 768px) {
.cadre[data-v-f7997c7a] {
    border: none;
}
}
@media screen and (max-width: 768px) {
.cadre-content-principal[data-v-f7997c7a] {
    padding: 0 !important;
}
}
.cadre-conteneur[data-v-f7997c7a], .dossier-conteneur[data-v-f7997c7a] {
  height: 100%;
}
.cadre-conteneur .cadre-tete[data-v-f7997c7a], .dossier-conteneur .cadre-tete[data-v-f7997c7a] {
    padding: 30px;
}
.title[data-v-f7997c7a] {
  color: #424242;
}
.cadre-content[data-v-f7997c7a] {
  height: 100%;
}
.dossier-content[data-v-f7997c7a] {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.btn-next[data-v-f7997c7a] {
  margin-right: 0;
  margin-left: auto;
}
label[for='nav'][data-v-f7997c7a] {
  cursor: pointer;
  position: absolute;
  z-index: 50;
  left: 10px;
  top: 10px;
}
@media screen and (min-width: 769px) {
label[for='nav'][data-v-f7997c7a] {
      display: none;
}
}
#nav[data-v-f7997c7a] {
  display: none;
}
#nav:checked ~ .background[data-v-f7997c7a] {
  display: block;
}
#nav:checked ~ .cadre-content .cadre-left[data-v-f7997c7a] {
  left: 0;
}
.background[data-v-f7997c7a] {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
  width: 100%;
  height: 100%;
}
.cadre-left[data-v-f7997c7a] {
  width: 25%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 768px) {
.cadre-left[data-v-f7997c7a] {
      position: absolute;
      left: -100%;
      top: 0;
      width: calc(100% - 70px);
      max-width: 500px;
      z-index: 1000;
      background: white;
      transition: left .3s ease-in;
}
}
.navigation[data-v-f7997c7a] {
  overflow-y: auto;
  flex-grow: 1;
  height: 100%;
}
.menu-contextuel[data-v-f7997c7a] {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 3px;
  flex-shrink: 1;
  max-height: 230px;
  font-size: .9rem;
}
.menu-contextuel .menu-en-tete[data-v-f7997c7a] {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    color: #333;
    font-size: 18px;
}
.menu-contextuel li[data-v-f7997c7a] {
    margin-bottom: 15px;
    list-style: none;
}
.menu-contextuel ul[data-v-f7997c7a] {
    margin: 0;
    padding: 0;
}
.menu-contextuel .cadre-content[data-v-f7997c7a] {
    overflow: hidden;
    padding: 0;
}
.menu-contextuel .metriques[data-v-f7997c7a] {
    display: flex;
}
.menu-contextuel .metriques .values[data-v-f7997c7a] {
      text-align: right;
      color: #105BFB;
      font-weight: 500;
      margin: 0 0px 0 auto;
}
.menu-contextuel.menu-contextuel--revenu[data-v-f7997c7a] {
    min-height: 300px;
    max-height: none !important;
}
.menu-contextuel.menu-contextuel--bien[data-v-f7997c7a] {
    min-height: 200px;
    max-height: none !important;
}
.menu-contextuel.menu-contextuel--epargne[data-v-f7997c7a] {
    min-height: 200px;
    max-height: none !important;
}
.settings[data-v-f7997c7a] {
  position: relative;
}

/*.router-content {
    @media screen and (max-width: 768px) {
        padding-top: 45px;
    }
}*/
.actions-container[data-v-f7997c7a] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.conteneur[data-v-1a42d34a] {
  height: 100%;
}
.modal-content[data-v-1a42d34a] {
  background: #E62565;
  color: white;
  padding: 20px;
  text-align: center;
}

.conteneur[data-v-37715b94] {
  color: white;
  background: #E62565;
  height: 100%;
  width: 100%;
}
.conteneur h1[data-v-37715b94] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.stella[data-v-78b0a06f] {
  margin-top: 10px;
  display: flex;
  height: 60px !important;
}
.load[data-v-78b0a06f] {
  display: flex;
  text-align: center;
}
.loader[data-v-78b0a06f] {
  border: 11px solid #f3f3f3;
  border-radius: 50%;
  border-top: 11px solid #3498db;
  width: 39px;
  height: 39px;
  /* Safari */
  animation: spin-data-v-78b0a06f 1s linear infinite;
}

/* Safari */
@keyframes spin-data-v-78b0a06f {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
.btn[data-v-78b0a06f] {
  min-width: 50px;
  margin-bottom: 5px;
  height: 46px !important;
  border-radius: 5px !important;
  padding: 0 20px;
  text-transform: none !important;
  color: #105BFB;
  cursor: pointer;
  transition: all 0.2s;
}
.btn[data-v-78b0a06f]:hover {
  background-color: #105BFB;
  color: aliceblue;
}
.btn-file[data-v-78b0a06f] {
  position: relative;
  overflow: hidden;
  border: 1px solid #105BFB;
  background: transparent;
  display: inline-block;
}
.btn-file input[type=file][data-v-78b0a06f] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 50px;
  margin-bottom: 5px;
  height: 40px;
  padding: 0 20px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  width: -webkit-fill-available;
}
.align[data-v-78b0a06f] {
  display: flex;
  justify-content: center;
  gap: 7px;
}
.icon-custom[data-v-78b0a06f] {
  color: #305ab5;
  font-size: 20px;
}
.icon-custom-cross[data-v-78b0a06f] {
  color: #ca2722;
  font-size: 20px;
  border: none;
  margin-right: 10px;
}
.cross[data-v-78b0a06f] {
  border: none;
  background: transparent;
}
.icons[data-v-78b0a06f]:hover {
  color: aliceblue;
}
.fade-enter-active[data-v-78b0a06f],
.fade-leave-active[data-v-78b0a06f] {
  transition: all  1s ease;
}
.fade-enter-from[data-v-78b0a06f],
.fade-leave-to[data-v-78b0a06f] {
  opacity: 0;
}
.select[data-v-78b0a06f] {
  background-color: transparent;
  border-radius: 5px  !important;
  border: 5px solid #D5E8FA;
  padding: 0 1em 0 0;
  margin: 0;
  width: 285px !important;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}
select[data-v-78b0a06f]::-ms-expand {
  display: none;
}
.stella-bis[data-v-78b0a06f] {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
.loader[data-v-78b0a06f] {
    width: 24px;
    height: 22px;
}
}

.load[data-v-2ea57c8d] {
  display: flex;
  text-align: center;
}
.loader[data-v-2ea57c8d] {
  border: 11px solid #f3f3f3;
  border-radius: 50%;
  border-top: 11px solid #3498db;
  width: 39px;
  height: 39px;
  /* Safari */
  animation: spin-data-v-2ea57c8d 1s linear infinite;
}

/* Safari */
@keyframes spin-data-v-2ea57c8d {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
.couleur1[data-v-2ea57c8d] {
  color: crimson;
}
.couleur2[data-v-2ea57c8d] {
  color: blue;
}
.couleur3[data-v-2ea57c8d] {
  color: forestgreen;
}
@media only screen and (max-width: 767px) {
.loader[data-v-2ea57c8d] {
    width: 24px;
    height: 22px;
}
}

.cadre-content[data-v-971442ba] {
  border: 10px solid   #D5E8FA;
  border-radius: 4px !important;
  text-align: center;
}
.scroll-container[data-v-971442ba] {
  width: 100%;
  max-height: 50vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.cadre-document[data-v-971442ba] {
  height: 100%;
  border-radius: 5px !important;
  background-color: #F2F3F3;
}
.fa-folder-open[data-v-971442ba]:before {
  content: "\F15B";
}
body[data-v-971442ba] {
  background-color: #2590EB;
  height: 100%;
}
.responsive-table[data-v-971442ba] {
  margin-left: -38px;
}
.responsive-table li[data-v-971442ba] {
    border-radius: 5px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.responsive-table .table-row[data-v-971442ba] {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    margin-left: 11px;
    margin-right: 11px;
    margin-top: 11px;
    margin-bottom: 11px;
}
.responsive-table .col-1[data-v-971442ba] {
    flex-basis: 13%;
    text-align: center;
}
.responsive-table .col-2[data-v-971442ba] {
    flex-basis: 40%;
}
.responsive-table .col-3[data-v-971442ba] {
    flex-basis: 20%;
}
@media only screen and (max-width: 767px) {
.responsive-table li[data-v-971442ba] {
      display: block;
}
}

.margin[data-v-70cd738f] {
  margin-top: -21px;
  margin-bottom: 30px;
}
.cadre[data-v-70cd738f] {
  padding: 20px;
  border: 1px solid #ffffff !important;
  height: 100% !important;
}
.fa-folder-open[data-v-70cd738f]:before {
  margin-right: 5px;
  font-weight: bold;
  content: "\F07C";
}
.fa-folder-close[data-v-70cd738f]:before {
  margin-right: 5px;
  content: "\F07B   ";
}
.fa-arrow[data-v-70cd738f]:before {
  content: "\F35B";
}
.couleur1[data-v-70cd738f] {
  color: crimson;
}
.couleur2[data-v-70cd738f] {
  color: blue;
}
.couleur3[data-v-70cd738f] {
  color: forestgreen;
}
.fa-check-circle[data-v-70cd738f] {
  content: "\F058";
  margin-left: 15px;
}
.fa-times-circle[data-v-70cd738f] {
  content: "\F057";
  margin-left: 15px;
}

/* original idea http://www.bootply.com/phf8mnMtpe */
.tree[data-v-70cd738f] {
  min-height: 20px;
  padding: 19px;
  margin-bottom: -40px;
  background-color: #fbfbfb;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(61, 22, 122, 0.05);
}
.tree li[data-v-70cd738f] {
  list-style-type: none;
  margin: 0;
  padding: 6px 5px 0 5px;
  position: relative;
}
.tree li[data-v-70cd738f]::before, .tree li[data-v-70cd738f]::after {
  content: '';
  left: -20px;
  position: absolute;
  right: auto;
}
.tree li[data-v-70cd738f]::before {
  border-left: 2px solid  #1a73e8;
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}
.tree li[data-v-70cd738f]::after {
  border-top: 2px solid  #1a73e8;
  height: 20px;
  top: 30px;
  width: 25px;
}
.tree li span[data-v-70cd738f] {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 2px solid  #1a73e8;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
}
.tree li.parent_li > span[data-v-70cd738f] {
  cursor: pointer;
}
.tree > ul > li[data-v-70cd738f]::before, .tree > ul > li[data-v-70cd738f]::after {
  border: 0;
}
.tree li[data-v-70cd738f]:last-child::before {
  height: 30px;
}
.tree li.parent_li > span[data-v-70cd738f]:hover, .tree li.parent_li > span:hover + ul li span[data-v-70cd738f] {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000;
}
.fade-enter-active[data-v-70cd738f],
.fade-leave-active[data-v-70cd738f] {
  transition: all  1s ease;
}
.fade-enter-from[data-v-70cd738f],
.fade-leave-to[data-v-70cd738f] {
  opacity: 0;
}
.list-move[data-v-70cd738f],
.list-enter-active[data-v-70cd738f],
.list-leave-active[data-v-70cd738f] {
  transition: all 1s;
}
.list-enter-from[data-v-70cd738f],
.list-leave-to[data-v-70cd738f] {
  opacity: 0;
  transition: all 1s;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
    animations can be calculated correctly. */
.list-leave-active[data-v-70cd738f] {
  position: absolute;
}
.a[data-v-70cd738f] {
  border-radius: 5px !important;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.stella[data-v-70cd738f] {
  background-color: #D5E8FA;
  font-weight: bold;
}

div[data-v-5e14ebd9] {
  width: 100%;
  height: 100vh;
}

.modal-backdrop[data-v-0d6cefb6] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(103, 143, 177, 0.562);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal[data-v-0d6cefb6] {
  background: #eeeeee;
  width: 50% !important;
  height: 70% !important;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px !important;
}
.modal-header[data-v-0d6cefb6] {
  padding: 15px;
  background: #305ab5;
  display: flex;
  border-radius: 5px !important;
}
.modal-header[data-v-0d6cefb6] {
  border-bottom: 1px solid #eeeeee;
  font-size: 30px;
  color: #305ab5;
  justify-content: center;
}
.modal-body[data-v-0d6cefb6] {
  position: relative;
  font-size: 30px;
  align-self: center;
  padding: 20px 10px;
}
.close[data-v-0d6cefb6] {
  border-color: #305ab5;
  font-size: 30px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #305ab5;
  background: transparent;
}
.btn[data-v-0d6cefb6] {
  min-width: 50px;
  margin-bottom: 5px;
  background-color: #ffffff;
  height: 40px;
  border-radius: 2px;
  padding: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;
}
.btn[data-v-0d6cefb6]:hover {
  background-color: #105BFB;
}
.btn-file[data-v-0d6cefb6] {
  position: relative;
  overflow: hidden;
  border: 1px solid #305ab5;
  display: inline-block;
}
.icon-custom[data-v-0d6cefb6] {
  color: #305ab5;
  font-size: 20px;
}
.icons[data-v-0d6cefb6]:hover {
  color: aliceblue;
}
.fa-eye-close[data-v-0d6cefb6]:before {
  content: "\F070   ";
}
@media only screen and (max-width: 767px) {
.modal[data-v-0d6cefb6] {
    width: 100% !important;
    height: 100% !important;
}
}

.title[data-v-935818ae] {
  position: relative;
  color: #105BFB;
  border-bottom: 1px solid #105BFB;
  padding: 15px 8px;
  text-align: center;
}
.cont[data-v-935818ae] {
  padding: 20px;
  overflow-y: scroll;
  overflow-x: unset;
  height: 100% !important;
}
.cont.cadre-content-principal[data-v-935818ae] {
    padding: 10px;
}
.cadre-folder[data-v-935818ae] {
  max-width: 400px;
  margin: left;
}
.menu-brique a[data-v-935818ae] {
  border-radius: 0;
}
.cadre-pied[data-v-935818ae] {
  margin-left: auto;
}
@media (max-width: 350px) {
.piece-conteneur[data-v-935818ae] {
    flex-direction: column;
}
}
@media (min-width: 351px) {
.piece-conteneur[data-v-935818ae] {
    flex-direction: row;
}
}
.actions-container[data-v-935818ae] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
.modalWebWiew[data-v-935818ae] {
  position: absolute;
  top: 15px;
  width: 100%;
}
.fade-enter-active[data-v-935818ae],
.fade-leave-active[data-v-935818ae] {
  transition: all  1s ease;
}
.fade-enter-from[data-v-935818ae],
.fade-leave-to[data-v-935818ae] {
  opacity: 0;
}

.title[data-v-171a6f1f] {
  position: relative;
  color: #105BFB;
  border-bottom: 1px solid #105BFB;
  padding: 15px 8px;
  text-align: center;
}
.cont[data-v-171a6f1f] {
  padding: 20px;
  overflow-y: scroll;
  overflow-x: unset;
  height: 100% !important;
}
.cont.cadre-content-principal[data-v-171a6f1f] {
    padding: 10px;
}
.cadre-folder[data-v-171a6f1f] {
  max-width: 400px;
  margin: left;
}
.menu-brique a[data-v-171a6f1f] {
  border-radius: 0;
}
.cadre-pied[data-v-171a6f1f] {
  margin-left: auto;
}
@media (max-width: 350px) {
.piece-conteneur[data-v-171a6f1f] {
    flex-direction: column;
}
}
@media (min-width: 351px) {
.piece-conteneur[data-v-171a6f1f] {
    flex-direction: row;
}
}
.actions-container[data-v-171a6f1f] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
.modalWebWiew[data-v-171a6f1f] {
  position: absolute;
  top: 15px;
  width: 100%;
}
.fade-enter-active[data-v-171a6f1f],
.fade-leave-active[data-v-171a6f1f] {
  transition: all  1s ease;
}
.fade-enter-from[data-v-171a6f1f],
.fade-leave-to[data-v-171a6f1f] {
  opacity: 0;
}

.loader[data-v-4ad14bc6] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
}
.dossier-client-app[data-v-4ad14bc6] {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  color: #555;
  font-weight: 400;
  position: fixed;
  top: 0;
  width: 100%;
}
.dossier-client-app *[data-v-4ad14bc6] {
    box-sizing: border-box !important;
}

@charset "UTF-8";
.dossier-client-app {
  /**
  *
  * FONT-AWESOME
  *
  */
  /**
  *
  * Vue Multiselect
  *
  */
  /**
  *
  * PROJECT
  *
  */ }
  .dossier-client-app input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px white inset; }
  .dossier-client-app .form-control {
    border: 0;
    border-radius: 2px;
    height: 38px;
    padding: 10px;
    transition: all .2s;
    color: #444;
    outline: none;
    width: 100%;
    box-shadow: inset 0 0 0 1px #E8E8E8;
    background: #fff;
    box-sizing: border-box; }
    .dossier-client-app .form-control:-ms-input-placeholder {
      color: gray; }
    .dossier-client-app .form-control::placeholder {
      color: gray; }
    .dossier-client-app .form-control:not(:disabled):active, .dossier-client-app .form-control:not(:disabled):focus, .dossier-client-app .form-control:not(:disabled):hover {
      color: #105BFB;
      box-shadow: inset 0 0 0 1px #105BFB; }
    .dossier-client-app .form-control:disabled {
      background: #E7E7E7;
      color: black; }
  .dossier-client-app textarea.form-control {
    height: auto !important; }
  .dossier-client-app .form-group {
    margin-bottom: 20px;
    position: relative; }
    .dossier-client-app .form-group.form-group--error input, .dossier-client-app .form-group.form-group--error select, .dossier-client-app .form-group.form-group--error textarea, .dossier-client-app .form-group.form-group--error .multiselect__tags {
      color: #E62565;
      box-shadow: 0 0 0 1px #E62565; }
    .dossier-client-app .form-group .form-error {
      color: #E62565;
      font-size: .75rem;
      margin-top: 5px;
      position: absolute;
      bottom: -20px;
      right: 0; }
  .dossier-client-app .form-cadre {
    background: #FAFAFA;
    border: 1px solid #E7E7E7;
    padding: 15px;
    margin-top: 15px; }
    .dossier-client-app .form-cadre .cadre-titre {
      padding: 10px 0 15px;
      font-size: .9em;
      color: #777; }
    .dossier-client-app .form-cadre .icon-trash {
      width: 100%;
      text-align: right;
      color: #105BFB; }
  .dossier-client-app .form-info {
    font-size: .7rem;
    font-weight: 500;
    margin-top: 5px;
    position: absolute; }
  .dossier-client-app label {
    display: block;
    margin-bottom: 10px; }
  .dossier-client-app input, .dossier-client-app select {
    width: 100%; }
  .dossier-client-app input, .dossier-client-app textarea {
    font-family: inherit; }
  .dossier-client-app .form-symbole {
    position: relative;
    height: 36px; }
    .dossier-client-app .form-symbole > input {
      margin-top: -1px; }
    .dossier-client-app .form-symbole::after {
      width: 30px;
      height: 100%;
      background: #E7E7E7;
      position: absolute;
      right: 1px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px; }
    .dossier-client-app .form-symbole.form-pourcentage::after {
      content: '%'; }
    .dossier-client-app .form-symbole.form-euros::after, .dossier-client-app .form-symbole.form-euro::after {
      content: '\20AC'; }
    .dossier-client-app .form-symbole.form-FCFA::after {
      content: 'FCFA'; }
    .dossier-client-app .form-symbole.form-chf::after {
      content: 'CHF';
      font-size: 10px; }
  .dossier-client-app .label-complement {
    font-size: .6rem;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    margin-top: -10px; }
  .dossier-client-app .btn {
    min-width: 50px;
    height: 40px;
    border-radius: 2px;
    padding: 0 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s; }
    .dossier-client-app .btn:not(.btn-large) {
      max-width: 200px; }
    .dossier-client-app .btn.btn-large {
      width: 100% !important; }
    .dossier-client-app .btn.btn-bordered {
      border: 1px solid #105BFB;
      background: transparent;
      color: #105BFB; }
    .dossier-client-app .btn.btn-default {
      border: 1px solid #105BFB;
      background: white;
      color: #105BFB; }
      .dossier-client-app .btn.btn-default:hover {
        border-color: #74a0fd; }
    .dossier-client-app .btn.btn-primary {
      border: white;
      color: white;
      background: #105BFB; }
      .dossier-client-app .btn.btn-primary:hover {
        background: #427dfc; }
    .dossier-client-app .btn.btn-next span {
      margin-left: 5px;
      font-size: 15px; }
    .dossier-client-app .btn.btn-previous span {
      margin-right: 5px;
      font-size: 15px; }
    .dossier-client-app .btn.btn-transparent {
      border: 1px solid white;
      background: transparent;
      color: white; }
      .dossier-client-app .btn.btn-transparent:hover {
        background: rgba(255, 255, 255, 0.2); }
    .dossier-client-app .btn:active, .dossier-client-app .btn:hover, .dossier-client-app .btn:focus {
      outline: 0; }
    .dossier-client-app .btn.btn-large {
      width: 100%; }
    .dossier-client-app .btn .etape-suivante {
      font-size: .6rem;
      text-transform: lowercase; }
    .dossier-client-app .btn.btn-ajouter {
      border: 1px solid #105BFB;
      text-transform: initial;
      max-width: 100%;
      line-height: 40px;
      padding: 0 10px; }
      .dossier-client-app .btn.btn-ajouter:hover {
        background: #105BFB;
        color: #fff; }
      .dossier-client-app .btn.btn-ajouter:active {
        background: #5b8ffc;
        color: #fff; }
    .dossier-client-app .btn.cancel-button {
      display: none; }
      @media screen and (max-width: 768px) {
        .dossier-client-app .btn.cancel-button {
          display: block; } }
    .dossier-client-app .btn.btn-save-next {
      display: block; }
      @media screen and (max-width: 768px) {
        .dossier-client-app .btn.btn-save-next {
          display: none; } }
  .dossier-client-app .etape-container {
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box; }
    .dossier-client-app .etape-container .etape-menu,
    .dossier-client-app .etape-container .etape-content {
      width: 50%;
      height: 100%; }
    .dossier-client-app .etape-container .etape-content .cadre-tete {
      text-transform: uppercase; }
    @media screen and (max-width: 768px) {
      .dossier-client-app .etape-container .etape-menu,
      .dossier-client-app .etape-container .etape-content {
        flex-shrink: 0;
        width: 100%;
        transition: all .5s ease;
        margin-right: -100%;
        position: relative;
        z-index: 10; }
        .dossier-client-app .etape-container .etape-menu.menu-active,
        .dossier-client-app .etape-container .etape-content.menu-active {
          margin-right: 0; } }
  .dossier-client-app .cadre {
    border: 1px solid #E7E7E7;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 100%; }
    .dossier-client-app .cadre form {
      height: 100%; }
    .dossier-client-app .cadre .cadre-tete {
      min-height: 60px;
      height: 60px;
      color: #616161;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dossier-client-app .cadre .cadre-tete h2 {
        color: #105BFB;
        font-weight: 400; }
      .dossier-client-app .cadre .cadre-tete .cadre-title {
        width: 100%;
        margin: 20px; }
      .dossier-client-app .cadre .cadre-tete.cadre-tete--menu {
        min-height: 50px;
        height: 50px;
        color: #105BFB;
        margin: 5px 15px 0;
        border-bottom: 1px solid #105BFB; }
        .dossier-client-app .cadre .cadre-tete.cadre-tete--menu a {
          color: #105BFB; }
          .dossier-client-app .cadre .cadre-tete.cadre-tete--menu a:hover {
            color: #427dfc; }
    .dossier-client-app .cadre .cadre-separator {
      width: 100%;
      display: block;
      height: 0;
      border-bottom: 1px solid #E7E7E7; }
    .dossier-client-app .cadre .cadre-content {
      padding: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100% !important; }
      .dossier-client-app .cadre .cadre-content.cadre-content-principal {
        padding: 10px; }
    .dossier-client-app .cadre .cadre-pied {
      height: 60px;
      margin-bottom: 0;
      margin-top: auto;
      display: flex;
      align-items: center;
      padding: 15px;
      justify-content: space-between; }
      .dossier-client-app .cadre .cadre-pied.cadre-pied--crud button[type=submit]:nth-child(1) {
        margin-left: 0;
        margin-right: 0; }
      .dossier-client-app .cadre .cadre-pied button[type=submit] {
        margin-left: auto;
        margin-right: 0; }
    .dossier-client-app .cadre.cadre-selected {
      border: 2px solid #105BFB; }
    .dossier-client-app .cadre .cadre-subpart-header {
      width: calc(100% + 40px);
      margin-left: -20px;
      padding: 15px 20px;
      height: 50px;
      border-top: 1px solid #E7E7E7;
      border-bottom: 1px solid #E7E7E7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #FAFAFA; }
  .dossier-client-app .content-cadre {
    margin: 0 15px; }
    .dossier-client-app .content-cadre:last-child {
      margin-right: 0 !important; }
    @media screen and (max-width: 768px) {
      .dossier-client-app .content-cadre {
        margin: 0; } }
  .dossier-client-app .text-primary {
    color: #105BFB; }
  .dossier-client-app .text-secondary {
    color: #1EAAF1; }
  .dossier-client-app .text-success {
    color: #159588; }
  .dossier-client-app .text-error {
    color: #E62565; }
  .dossier-client-app .text-muted {
    color: #616161; }
  .dossier-client-app .text-italic {
    font-style: italic; }
  .dossier-client-app .text-uppercase {
    text-transform: uppercase; }
  .dossier-client-app .font-weight-bold {
    font-weight: bold; }
  .dossier-client-app .font-weight-normal {
    font-weight: 500; }
  .dossier-client-app .background-primary {
    color: white !important;
    background: #105BFB; }
  .dossier-client-app .background-gray {
    background: #FAFAFA !important; }
  .dossier-client-app .background-white {
    background: white; }
  .dossier-client-app .list-style-none {
    list-style: none; }
  .dossier-client-app .cursor-pointer {
    cursor: pointer; }
  .dossier-client-app .list-unstyled {
    list-style: none;
    margin: 0;
    padding: 0; }
  .dossier-client-app .text-center {
    text-align: center; }
  .dossier-client-app .no-margin {
    margin: 0; }
  .dossier-client-app .disabled {
    pointer-events: none;
    color: #E7E7E7; }
  .dossier-client-app .checkbox {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    -ms-user-select: none;
        user-select: none;
    color: #616161; }
    .dossier-client-app .checkbox input[type=checkbox] {
      display: none; }
    .dossier-client-app .checkbox .checkmark {
      position: absolute;
      left: 0;
      top: 10px;
      transform: translateY(-50%);
      width: 18px;
      height: 17px;
      border-radius: 2px;
      box-shadow: 0 0 0 1px black; }
    .dossier-client-app .checkbox input[type=checkbox]:checked ~ .checkmark {
      box-shadow: 0 0 0 1px #105BFB; }
      .dossier-client-app .checkbox input[type=checkbox]:checked ~ .checkmark::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: '\F00C';
        color: #105BFB;
        text-align: center;
        width: 100%;
        display: block;
        height: 100%; }
    .dossier-client-app .checkbox p {
      font-size: 0.75rem;
      font-weight: 100; }
  .dossier-client-app .cadre-title {
    position: relative;
    color: #105BFB;
    border-bottom: 1px solid #105BFB;
    padding: 15px 8px; }
    .dossier-client-app .cadre-title .cadre-title-creer {
      display: flex;
      justify-content: space-between; }
  .dossier-client-app .menu-brique {
    margin-bottom: 15px;
    background: #fff; }
    .dossier-client-app .menu-brique > div:first-child:not(.router-link-active) {
      box-shadow: 0 0 0 1px #E7E7E7; }
    .dossier-client-app .menu-brique .router-link-active {
      box-shadow: 0 0 0 1px #105BFB; }
      .dossier-client-app .menu-brique .router-link-active a {
        background: #105BFB;
        color: #fff; }
    .dossier-client-app .menu-brique a {
      padding: 15px;
      margin-bottom: 0;
      text-decoration: none;
      color: #424242;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all .2s; }
      .dossier-client-app .menu-brique a > div {
        display: flex;
        align-items: center; }
      .dossier-client-app .menu-brique a:hover {
        background: #105BFB;
        color: white; }
        .dossier-client-app .menu-brique a:hover .menu-contact {
          color: #aeaeae; }
    .dossier-client-app .menu-brique.brique-creer {
      background: #105BFB; }
      .dossier-client-app .menu-brique.brique-creer a {
        color: white;
        display: none; }
        .dossier-client-app .menu-brique.brique-creer a.router-link-active {
          display: block; }
    .dossier-client-app .menu-brique:last-child {
      margin-bottom: 0; }
    .dossier-client-app .menu-brique .avatar-icon {
      margin-right: 15px;
      font-size: 20px; }
    .dossier-client-app .menu-brique .infos {
      padding: 15px;
      box-shadow: inset 0 0 0 1px #E7E7E7; }
    .dossier-client-app .menu-brique .info-group {
      font-size: .9rem; }
      .dossier-client-app .menu-brique .info-group label {
        font-size: .8rem;
        color: #616161; }
    .dossier-client-app .menu-brique .menu-metric-title {
      font-size: .8rem;
      margin-bottom: 5px;
      font-weight: 100; }
    .dossier-client-app .menu-brique .menu-metrics {
      padding: 15px; }
    .dossier-client-app .menu-brique .menu-form-complet {
      font-size: 0.8rem;
      padding: 15px; }
      .dossier-client-app .menu-brique .menu-form-complet.menu-form-complet--complet {
        color: #159588; }
      .dossier-client-app .menu-brique .menu-form-complet.menu-form-complet--noncomplet {
        color: #616161; }
    .dossier-client-app .menu-brique .menu-brique-contenu {
      border-top: 1px solid #E7E7E7; }
      .dossier-client-app .menu-brique .menu-brique-contenu ul {
        margin-bottom: 0; }
    .dossier-client-app .menu-brique .menu-brique-pied {
      border-top: 1px solid #E7E7E7; }
  .dossier-client-app .cadre-precision {
    background: white;
    border-top: 1px solid #E7E7E7;
    min-height: 135px;
    height: 135px;
    cursor: pointer; }
    .dossier-client-app .cadre-precision.precision-simple {
      min-height: 125px;
      height: 125px; }
    .dossier-client-app .cadre-precision.router-link-active {
      box-shadow: inset 0 0 0 1px #105BFB; }
      .dossier-client-app .cadre-precision.router-link-active .precision-title {
        color: #105BFB; }
    .dossier-client-app .cadre-precision .precision-title {
      display: flex;
      width: 100%;
      height: 30px;
      padding: 15px; }
    .dossier-client-app .cadre-precision .precision-content {
      padding: 15px;
      color: #616161;
      height: calc(100% - 30px);
      overflow: hidden; }
      .dossier-client-app .cadre-precision .precision-content > div {
        margin-bottom: 10px; }
    .dossier-client-app .cadre-precision .edit-icon {
      margin-left: auto;
      margin-right: 0; }
    .dossier-client-app .cadre-precision .form-group {
      flex-grow: 1;
      overflow: hidden;
      font-size: .9rem;
      margin-bottom: 10px; }
      .dossier-client-app .cadre-precision .form-group label {
        font-size: .8rem;
        margin-bottom: 0; }
  .dossier-client-app .notification-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    padding: 20px;
    border-radius: 2px;
    color: white;
    text-align: center; }
    .dossier-client-app .notification-container.success {
      background: #159588; }
    .dossier-client-app .notification-container.warning {
      background: #f76300; }
    .dossier-client-app .notification-container.error {
      background: #E62565; }
  .dossier-client-app .notification-afficher-enter-active, .dossier-client-app .notification-afficher-leave-active {
    transition: all 0s; }
  .dossier-client-app .notification-afficher-enter, .dossier-client-app .notification-afficher-leave-to {
    top: -100%; }
  .dossier-client-app .modal-container .modal-content {
    position: absolute;
    overflow-y: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100px;
    max-height: calc(100% - 30px);
    min-width: 400px;
    background: white;
    z-index: 20; }
    .dossier-client-app .modal-container .modal-content.modal-content--full-width {
      width: calc(100% - 30px); }
    .dossier-client-app .modal-container .modal-content p {
      all: unset; }
  .dossier-client-app .modal-container .cadre-pied {
    padding: 0 15px; }
  .dossier-client-app .modal-container .cadre-tete {
    padding: 0; }
  .dossier-client-app .modal-container .modal-background {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10; }
  .dossier-client-app .modal-appear-enter-active, .dossier-client-app .modal-appear-leave-active {
    transition: all .3s; }
  .dossier-client-app .modal-appear-enter, .dossier-client-app .modal-appear-leave-to {
    opacity: 0; }
  .dossier-client-app .fade-enter-active,
  .dossier-client-app .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease; }
  .dossier-client-app .fade-enter,
  .dossier-client-app .fade-leave-active {
    opacity: 0; }
    @media screen and (max-width: 768px) {
      .dossier-client-app .fade-enter,
      .dossier-client-app .fade-leave-active {
        opacity: 1; } }
  .dossier-client-app .saving span {
    font-size: 1.3rem;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite; }
    .dossier-client-app .saving span:nth-child(2) {
      animation-delay: .2s; }
    .dossier-client-app .saving span:nth-child(3) {
      animation-delay: .4s; }

@keyframes blink {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }
  .dossier-client-app .confirm::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    top: 0;
    left: 0; }
  .dossier-client-app .confirm .confirm-container {
    position: absolute;
    min-width: 425px;
    min-height: 250px;
    background: white;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 425px) {
      .dossier-client-app .confirm .confirm-container {
        min-width: 100%; } }
  .dossier-client-app .confirm .confirm-content {
    display: flex;
    align-items: center;
    margin: auto 0;
    min-height: 200px;
    padding: 30px;
    line-height: 25pt; }
    .dossier-client-app .confirm .confirm-content .icon-status {
      width: 10%;
      font-size: 30px; }
  .dossier-client-app .confirm .confirm-footer {
    margin-top: auto;
    margin-bottom: 0;
    min-height: 50px;
    padding: 10px;
    display: flex;
    justify-content: space-between; }
    .dossier-client-app .confirm .confirm-footer .btn-ok {
      margin-right: 0;
      margin-left: auto; }
  .dossier-client-app .confirm.success .confirm-container {
    border: 2px solid #159588; }
  .dossier-client-app .confirm.success .confirm-footer {
    background: #159588; }
  .dossier-client-app .confirm.success .icon-status {
    color: #159588; }
  .dossier-client-app .confirm.error .confirm-container {
    border: 2px solid #E62565; }
  .dossier-client-app .confirm.error .confirm-footer {
    background: #E62565; }
  .dossier-client-app .confirm.error .icon-status {
    color: #E62565; }
  .dossier-client-app .confirm.ask-confirm .confirm-container {
    border: 2px solid #105BFB; }
  .dossier-client-app .confirm.ask-confirm .confirm-footer {
    background: #105BFB; }
  .dossier-client-app .confirm.ask-confirm .icon-status {
    color: #105BFB; }
  .dossier-client-app .vdp-datepicker__calendar .selected {
    background: #105BFB !important;
    color: white; }
  .dossier-client-app .vdp-datepicker__calendar .cell:not(.blank):hover {
    border: 1px solid #105BFB !important; }
  .dossier-client-app .vdp-datepicker__calendar .day-header {
    pointer-events: none; }
  .dossier-client-app .vdp-datepicker__calendar-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%); }
  .dossier-client-app .form-group .multiselect__option--highlight {
    background: #f9f9f9;
    color: #105BFB; }
  .dossier-client-app .form-group .multiselect {
    cursor: pointer; }
    .dossier-client-app .form-group .multiselect .multiselect__content-wrapper {
      z-index: 9 !important;
      width: auto; }
    .dossier-client-app .form-group .multiselect .multiselect__input {
      font-size: 13px;
      padding: 0;
      margin: 0; }
      .dossier-client-app .form-group .multiselect .multiselect__input:active, .dossier-client-app .form-group .multiselect .multiselect__input:focus {
        color: #105BFB; }
      .dossier-client-app .form-group .multiselect .multiselect__input:-ms-input-placeholder {
        color: gray; }
      .dossier-client-app .form-group .multiselect .multiselect__input::placeholder {
        color: gray; }
    .dossier-client-app .form-group .multiselect .multiselect__tags {
      box-shadow: 0 0 0 1px #E8E8E8;
      border: 0;
      padding: 10px;
      border-radius: 2px;
      font-size: 11px; }
      .dossier-client-app .form-group .multiselect .multiselect__tags .multiselect__single,
      .dossier-client-app .form-group .multiselect .multiselect__tags .multiselect__strong {
        padding-left: 0;
        margin-bottom: 0 !important; }
    .dossier-client-app .form-group .multiselect .multiselect__element {
      border-top: 1px solid #ddd !important; }
    .dossier-client-app .form-group .multiselect.multiselect--active .multiselect__tags {
      box-shadow: 0 0 0 1px #105BFB; }
    .dossier-client-app .form-group .multiselect .multiselect__single {
      font-size: 13px; }
    .dossier-client-app .form-group .multiselect.no-multiline .multiselect__single {
      display: none; }
    .dossier-client-app .form-group .multiselect strong {
      font-weight: 400;
      font-size: 13px !important;
      color: #555; }
    .dossier-client-app .form-group .multiselect .option__desc .fa-check-circle {
      color: #ddd;
      margin-right: 10px; }
      .dossier-client-app .form-group .multiselect .option__desc .fa-check-circle.active {
        color: #105BFB; }
  .dossier-client-app .form-group.form-group--error .multiselect__tags {
    box-shadow: 0 0 0 1px #E62565 !important; }
  .dossier-client-app .form-group .multiselect__placeholder {
    margin-bottom: 0 !important; }
  .dossier-client-app .form-group .vue-js-switch > span {
    z-index: 0 !important; }
  .dossier-client-app .form-group .vue-js-switch .v-switch-core > div {
    z-index: 0 !important; }
  .dossier-client-app .form-group .vue-js-switch .v-switch-label {
    font-size: 13px  !important;
    font-weight: 400 !important; }
  .dossier-client-app .form-group .vue-js-switch.toggled .v-switch-core {
    background-color: #105BFB !important; }
  .dossier-client-app .badge {
    background: #fdcb6e;
    color: #fff;
    border-radius: 4px;
    padding: 6px;
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
    vertical-align: middle; }
  .dossier-client-app table {
    text-align: left;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 5px; }
  .dossier-client-app thead tr th {
    border-bottom: 1px solid #E7E7E7;
    border-collapse: separate;
    border-spacing: 5px 5px; }
  .dossier-client-app .pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -ms-user-select: none;
        user-select: none; }
  .dossier-client-app .pace-inactive {
    display: none; }
  .dossier-client-app .pace .pace-progress {
    background: #105BFB;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px; }
  .dossier-client-app .pace .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #105BFB, 0 0 5px #105BFB;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px); }
  .dossier-client-app .pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: #105BFB;
    border-left-color: #105BFB;
    border-radius: 10px;
    animation: pace-spinner 400ms linear infinite; }

@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg); } }
  .dossier-client-app .autocomplete ul {
    z-index: 10;
    width: 100%;
    margin-top: 5px;
    box-shadow: 0px 0px 5px 0px #616161;
    background: white;
    padding: 0; }
  .dossier-client-app .autocomplete ul li a {
    background: white; }
  .dossier-client-app .autocomplete ul li a:hover, .dossier-client-app .autocomplete ul li.focus-list a {
    background: #105BFB; }
  .dossier-client-app .autocomplete ul:before {
    display: none; }
  .dossier-client-app #navigation .cadre {
    border: 0; }
  .dossier-client-app #navigation .cadre-content {
    padding-right: 0;
    overflow-y: scroll;
    overflow-x: hidden; }
  .dossier-client-app #navigation .etapes {
    position: relative;
    color: #424242;
    list-style: none;
    margin: 0;
    padding: 0; }
    .dossier-client-app #navigation .etapes:before {
      content: '';
      width: 2px;
      height: 100%;
      background: #E7E7E7;
      position: absolute;
      left: 7px;
      top: 20px; }
  .dossier-client-app #navigation .etape {
    cursor: pointer; }
    .dossier-client-app #navigation .etape i {
      margin-right: 0;
      margin-left: auto; }
      .dossier-client-app #navigation .etape i.etape--complete {
        color: #105BFB; }
      .dossier-client-app #navigation .etape i.etape--noncomplete {
        color: #e2e2e2; }
    .dossier-client-app #navigation .etape a {
      text-decoration: none;
      color: #888;
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 30px;
      position: relative; }
      .dossier-client-app #navigation .etape a .list-numero {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #ddd;
        left: 0;
        top: 0;
        transform: translateY(50%);
        font-size: .7rem;
        color: white;
        font-weight: bolder;
        display: flex;
        align-items: center;
        justify-content: center; }
    .dossier-client-app #navigation .etape.router-link-active a {
      color: #105BFB; }
      .dossier-client-app #navigation .etape.router-link-active a .list-numero {
        background: #105BFB; }
  .dossier-client-app #navigation .etape.router-link-active + .menu-formulaires {
    max-height: 40vh; }
  .dossier-client-app #navigation .menu-formulaires {
    transition: all .15s;
    max-height: 0;
    overflow: auto;
    box-shadow: inset 0 0 0 1px #E8E8E8;
    margin-left: 7px;
    display: flex; }
    .dossier-client-app #navigation .menu-formulaires::-webkit-scrollbar {
      display: none; }
    .dossier-client-app #navigation .menu-formulaires .menu-formulaire {
      display: flex; }
      .dossier-client-app #navigation .menu-formulaires .menu-formulaire i {
        margin-right: 0;
        margin-left: auto; }
    .dossier-client-app #navigation .menu-formulaires ul {
      background: white;
      overflow: hidden;
      list-style: none;
      margin: 1px 0;
      padding: 0;
      width: 100%; }
      .dossier-client-app #navigation .menu-formulaires ul li {
        margin-bottom: 0; }
        .dossier-client-app #navigation .menu-formulaires ul li a {
          padding: 12px 10px 12px 30px;
          text-decoration: none;
          color: #555;
          display: flex;
          width: 100%; }
          .dossier-client-app #navigation .menu-formulaires ul li a.router-link-active {
            color: #105BFB;
            background: #f5f5f5; }
  .dossier-client-app #navigation .formulaire--complete {
    color: #159588 !important; }
  .dossier-client-app #navigation .formulaire--noncomplete {
    color: #e2e2e2 !important; }

.formulaire-status[data-v-98004182] {
  text-align: center;
  color: #fdcb6e;
  font-size: .7rem;
  text-transform: none;
}

.text-warning {
  color: #ff5722;
  padding: 30px;
  border: 1px solid #ff5722;
  margin-bottom: 20px;
}

.kpis[data-v-112cb165] {
  list-style: none;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -10px 0;
  font-size: .8rem;
}
.kpi[data-v-112cb165] {
  width: 50%;
  margin-bottom: 10px;
  cursor: pointer;
}
.kpi[data-v-112cb165]:hover {
    color: #105BFB !important;
}

.loader[data-v-3a136c62] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
}
.dossier-client-app[data-v-3a136c62] {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  color: #555;
  font-weight: 400;
  position: fixed;
  top: 0;
  width: 100%;
}
.dossier-client-app *[data-v-3a136c62] {
    box-sizing: border-box !important;
}

.loader[data-v-6072c3ee] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
}
.dossier-client-app[data-v-6072c3ee] {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  color: #555;
  font-weight: 400;
  position: fixed;
  top: 0;
  width: 100%;
}
.dossier-client-app *[data-v-6072c3ee] {
    box-sizing: border-box !important;
}


/*# sourceMappingURL=app.c6cf3828.css.map*/