

















@import "../assets/scss/colors";

.conteneur {
    color: white;
    background: $error;
    height: 100%;
    width: 100%;
    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
