.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;

  @media screen and (max-width: 768px){
    opacity: 1;
  }
}

.saving span {
    font-size: 1.3rem;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;

    &:nth-child(2) {
        animation-delay: .2s;
    }

    &:nth-child(3) {
        animation-delay: .4s;
    }
}

@keyframes blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}
