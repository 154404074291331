@import 'colors';

.modal-container {

    .modal-content {
        position: absolute;
        overflow-y: scroll;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100px;
        max-height: calc(100% - 30px);
        min-width: 400px;
        background: white;
        z-index: 20;

        &.modal-content--full-width {
            width: calc(100% - 30px);
        }

        p {
          all: unset;
        }
    }

    .cadre-pied {
      padding: 0 15px;
    }

    .cadre-tete {
      padding: 0;
    }

    .modal-background {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
}

.modal-appear-enter-active, .modal-appear-leave-active {
    transition: all .3s;
}

.modal-appear-enter, .modal-appear-leave-to {
    opacity: 0;
}
