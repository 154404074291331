@import "colors";

.form-group {
    .vue-js-switch {

        > span {
            z-index: 0 !important;
        }

        .v-switch-core {
            > div {
                z-index: 0 !important;
            }
        }

        .v-switch-label {
            font-size: 13px  !important;
            font-weight: 400 !important;
        }

        &.toggled .v-switch-core {
            background-color: $primary !important;
        }
    }
}