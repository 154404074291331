@import "colors";
@import "variables";

.etape-container {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .etape-menu,
  .etape-content {
    width: 50%;
    height: 100%;
  }

  .etape-content {
    .cadre-tete {
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 768px) {
    .etape-menu,
    .etape-content {
      flex-shrink: 0;
      width: 100%;
      transition: all .5s ease;
      margin-right: -100%;
      position: relative;
      z-index: 10;

      &.menu-active {
        margin-right: 0;
      }
    }
  }
}

.cadre {
  border: 1px solid $gray-light;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  //height: calc(100vh - 42px);
  height: 100%;

  form {
    height: 100%;
  }

  .cadre-tete {
    min-height: $cadre-tete-height;
    height: $cadre-tete-height;
    color: $gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      color: $primary;
      font-weight: 400;
    }

    .cadre-title {
      width: 100%;
      margin: 20px;
    }

    &.cadre-tete--menu {
      min-height: $cadre-tete-menu-height;
      height: $cadre-tete-menu-height;
      color: $primary;
      margin: 5px 15px 0;
      border-bottom: 1px solid $primary;

      a {
        color: $primary;

        &:hover {
          color: lighten($primary, 10%);
        }
      }
    }
  }

  .cadre-separator {
    width: 100%;
    display: block;
    height: 0;
    border-bottom: 1px solid $gray-light;
  }

  .cadre-content {
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100% !important;

    &.cadre-content-principal {
      padding: 10px;
    }
  }

  .cadre-pied {
    height: $cadre-pied-height;
    margin-bottom: 0;
    margin-top: auto;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;

    &.cadre-pied--crud {
      button[type=submit]:nth-child(1) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    button[type=submit] {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &.cadre-selected {
    border: 2px solid $primary;
  }

  .cadre-subpart-header {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 15px 20px;
    height: 50px;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $gray-lighter;
  }
}

.content-cadre {

  &:last-child {
    margin-right: 0 !important;
  }

  margin: 0 15px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
}
