@import "colors";

.badge {
    background: $orange;
    color: #fff;
    border-radius: 4px;
    padding: 6px;
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
    vertical-align: middle;
}
