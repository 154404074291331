



























































 @import "../../assets/scss/colors";
.cadre-content{
 border:10px solid   #D5E8FA;
 border-radius: 4px !important; 
 text-align:center;
}
 .scroll-container {
     width: 100%;
     max-height:50vh;
     overflow-y: scroll;
     scroll-behavior: smooth;
     
 }
 .cadre-document {
     height: 100%;
     border-radius: 5px !important;
     background-color: #F2F3F3;
 }
 
 .fa-folder-open:before {
 content: "\f15b";
 }

 body{

 background-color:#2590EB;
 height:100%;
 }

 

 .responsive-table {
    
     margin-left: -38px;
 
 li {
     border-radius: 5px;
     padding: 25px 30px;
     display: flex;
     justify-content: space-between;
     margin-bottom: 25px;
 }
 .table-row {
     background-color: #ffffff;
     box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
     margin-left: 11px;
     margin-right: 11px;
     margin-top:11px;
     margin-bottom: 11px;
 }
 .col-1 {
     flex-basis: 13%;
     text-align: center;
    
 }
 .col-2 {
     flex-basis: 40%;
 
 }
 .col-3 {
     flex-basis: 20%;
     
 }
 
 @media only screen and (max-width: 767px) {
     
     li {
     display: block;
     }
    
     
 }
 
 }
 

