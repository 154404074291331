












































































































































































































@import "../../../../assets/scss/variables";

.btn-large {
    margin-bottom: 10px;
}
