@import "colors";

.cadre-title {
    position: relative;
    color: $primary;
    border-bottom: 1px solid $primary;
    padding: 15px 8px;

    .cadre-title-creer {
        display: flex;
        justify-content: space-between;
    }
}
