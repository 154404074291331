@import "colors";

.checkbox {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    user-select: none;
    color: $gray;

    input[type=checkbox] {
        display: none;
    }

    .checkmark {
        position: absolute;
        left: 0;
        top: 10px;
        transform: translateY(-50%);
        width: 18px;
        height: 17px;
        border-radius: 2px;
        box-shadow: 0 0 0 1px black;
    }

    input[type=checkbox]:checked ~ .checkmark {
        box-shadow: 0 0 0 1px $primary;
        &::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: '\f00c';
            color: $primary;
            text-align: center;
            width: 100%;
            display: block;
            height: 100%;
        }
    }

    p {
        font-size: 0.75rem;
        font-weight: 100;
    }

}
