

























@import "../assets/scss/colors";

.formulaire-status {
    text-align: center;
    color: $orange;
    font-size: .7rem;
    text-transform: none;
}
