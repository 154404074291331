




























































































































































































































    @import "../../assets/scss/colors";
    .margin{
        margin-top: -21px;
        margin-bottom: 30px;

    }   
    .cadre {
        padding: 20px;
        border: 1px solid #ffffff !important ;
        height: 100% !important;     
    }   
    .fa-folder-open:before {
        margin-right: 5px;
        font-weight: bold;
        content: "\F07C";
    }
    .fa-folder-close:before {
        margin-right: 5px;
        content: "\f07b ";
    }

    .fa-arrow:before {
    content: "\f35b";
    }

        .couleur1{
        color:crimson
    }
        .couleur2{
        color:blue
    }
        .couleur3{
        color:forestgreen
    }
    .fa-check-circle{
        content: "\f058";
            margin-left: 15px;
    }
    .fa-times-circle{
        content:"\f057";
        margin-left: 15px;
    }
/* original idea http://www.bootply.com/phf8mnMtpe */

    .tree {
        min-height:20px;
        padding:19px;
        margin-bottom:-40px;
        background-color:#fbfbfb;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        border-radius:4px;
        -webkit-box-shadow:inset 0 1px 1px rgba(61, 22, 122, 0.05);
        -moz-box-shadow:inset 0 1px 1px rgba(61, 22, 122, 0.05);
        box-shadow:inset 0 1px 1px rgba(61, 22, 122, 0.05);
    }
    .tree li {
        list-style-type:none;
        margin:0;
        padding:6px 5px 0 5px;
        position:relative
    }
    .tree li::before, .tree li::after {
        content:'';
        left:-20px;
        position:absolute;
        right:auto
    }
    .tree li::before {
        border-left:2px solid  #1a73e8;
        bottom:50px;
        height:100%;
        top:0;
        width:1px
    }
    .tree li::after {
        border-top:2px solid  #1a73e8;
        height:20px;
        top:30px;
        width:25px
    }
    .tree li span {
        -moz-border-radius:5px;
        -webkit-border-radius:5px;
        border:2px solid  #1a73e8;
        border-radius:5px;
        display:inline-block;
        padding:3px 8px;
        text-decoration:none
    }
    .tree li.parent_li>span {
        cursor:pointer
    }
    .tree>ul>li::before, .tree>ul>li::after {
        border:0
    }
    .tree li:last-child::before {
        height:30px
    }
    .tree li.parent_li>span:hover, .tree li.parent_li>span:hover+ul li span {
        background:#eee;
        border:1px solid #94a0b4;
        color:#000
    }    
    
    .fade-enter-active,
    .fade-leave-active {
        transition:  all  1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    
    }
    .list-move, /* apply transition to moving elements */
    .list-enter-active,
    .list-leave-active {
    transition: all 1s ;
    }

    .list-enter-from,
    .list-leave-to {
    opacity: 0;
    transition: all 1s ;
    transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
    animations can be calculated correctly. */
    .list-leave-active {
    position: absolute;
    }
    .a{
        border-radius: 5px !important;
         box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1); 
        
    } 
    .stella{
        
        background-color: #D5E8FA;
        font-weight: bold;
        
    }
    


    
