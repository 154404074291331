























































@import "../assets/scss/colors";

.conteneur {
    height: 100%;
}

.modal-content {
    background: $error;
    color: white;
    padding: 20px;
    text-align: center;
}

