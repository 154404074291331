























































































































































































































































































.cadre-conteneur {
    height: 100%;

    .cadre {
        background: #fff;
    }
}

.nouveau-dossier {
    max-width: 500px;
    height: 100%;
    margin: auto;

    .cadre-pied {
        justify-content: space-between;
    }

    .create-btn {
        width: 100%;
    }
}

