



























i.far {
    margin-right: 7px;
}
