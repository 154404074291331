@import "../../../node_modules/vue2-autocomplete-js/dist/style/vue2-autocomplete.css";
@import "colors";

.autocomplete ul {
    z-index: 10;
    width: 100%;
    margin-top: 5px;
    box-shadow: 0px 0px 5px 0px $gray;
    background: white;
    padding: 0;
}

.autocomplete ul li a {
    background: white;
}

.autocomplete ul li a:hover, .autocomplete ul li.focus-list a {
    background: $primary;
}

.autocomplete ul:before {
    display: none;
}
