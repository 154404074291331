












































































































































































.avatar-icon {
    margin-right: 10px;
    font-size: 30px;
}

.edit-icon {
    margin-right: 0;
    margin-left: auto;
}
