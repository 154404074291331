@import "colors";

.btn {
  min-width: 50px;
  height: 40px;
  border-radius: 2px;
  padding: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;

  &:not(.btn-large) {
    max-width: 200px;
  }

  &.btn-large {
    width: 100% !important;
  }

  &.btn-bordered {
    border: 1px solid $primary;
    background: transparent;
    color: $primary;
  }

  &.btn-default {
    border: 1px solid $primary;
    background: white;
    color: $primary;

    &:hover {
      border-color: lighten($primary, 20%);
    }
  }

  &.btn-primary {
    border: white;
    color: white;
    background: $primary;

    &:hover {
      background: lighten($primary, 10%);
    }
  }

  &.btn-next {
    span {
      margin-left: 5px;
      font-size: 15px;
    }
  }

  &.btn-previous {
    span {
      margin-right: 5px;
      font-size: 15px;
    }
  }

  &.btn-transparent {
    border: 1px solid white;
    background: transparent;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &:active, &:hover, &:focus {
    outline: 0;
  }

  &.btn-large {
    width: 100%;
  }

  .etape-suivante {
    font-size: .6rem;
    text-transform: lowercase;
  }

  &.btn-ajouter {
    border: 1px solid $primary;
    text-transform: initial;
    max-width: 100%;
    line-height: 40px;
    padding: 0 10px;

    &:hover {
      background: $primary;
      color: #fff;
    }

    &:active {
      background: lighten($primary, 15%);
      color: #fff;
    }
  }

  &.cancel-button {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  &.btn-save-next {
    display: block;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
