




























































.load{
    display: flex;
    text-align: center;
    
}
.loader {
    border: 11px solid #f3f3f3;
    border-radius: 50%;
    border-top: 11px solid #3498db;
    width: 39px;
    height: 39px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.couleur1{
    color:crimson
}
 .couleur2{
    color:blue
}
 .couleur3{
    color:forestgreen
}
@media only screen and (max-width: 767px) {
    
    .loader{
        width: 24px;
        height: 22px;
    }
    
}


