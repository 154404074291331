


























@import "../assets/scss/_colors.scss";
div {
  width: 100%; 
  height: 100vh;
}

