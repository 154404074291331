

























































































































































































































































































































































































































































































































































































































































































































































@import "../assets/scss/colors";
@import "../assets/scss/variables";

.chargement {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.8);
    position: absolute;
    z-index: 9999;

    div {
        position: absolute;
        color: $primary;
        left: 50%;
        top: 50%;
        font-size: 20px;
        margin-left: -120px;
    }
}

.cadre {
    @media screen and (max-width: 768px) {
        border: none;
    }
}

.cadre-content-principal {
    @media screen and (max-width: 768px) {
        padding: 0 !important;
    }
}

.cadre-conteneur, .dossier-conteneur {
    height: 100%;

    .cadre-tete {
        padding: 30px;
    }
}

.title {
    color: $black;
}

.cadre-content {
    height: 100%;
}

.dossier-content {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.btn-next {
    margin-right: 0;
    margin-left: auto;
}

label[for='nav'] {
    cursor: pointer;
    position: absolute;
    z-index: 50;
    left: 10px;
    top: 10px;

    @media screen and (min-width: 769px) {
        display: none;
    }
}

#nav {
    display: none;
}

#nav:checked ~ .background {
    display: block;
}

#nav:checked ~ .cadre-content {

    .cadre-left {
        left: 0;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
    display: none;
    width: 100%;
    height: 100%;
}

.cadre-left {
    width: 25%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media screen and (max-width: 768px) {
        position: absolute;
        left: -100%;
        top: 0;
        width: calc(100% - 70px);
        max-width: 500px;
        z-index: 1000;
        background: white;
        transition: left .3s ease-in;
    }
}

.navigation {
    overflow-y: auto;
    flex-grow: 1;
    height: 100%;
}

.menu-contextuel {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 3px;
    flex-shrink: 1;
    max-height: 230px;
    font-size: .9rem;

    .menu-en-tete {
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
        color: #333;
        font-size: 18px;
    }

    li {
        margin-bottom: 15px;
        list-style: none;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    .cadre-content {
        overflow: hidden;
        padding: 0;
    }

    .metriques {
        display: flex;

        .values {
            text-align: right;
            color: $primary;
            font-weight: 500;
            margin: 0 0px 0 auto;
        }
    }

    &.menu-contextuel--revenu{
        min-height: 300px;
        max-height: none !important;
    }

    &.menu-contextuel--bien{
        min-height: 200px;
        max-height: none !important;
    }

    &.menu-contextuel--epargne{
        min-height: 200px;
        max-height: none !important;
    }
}

.settings {
    position: relative;
}

/*.router-content {
    @media screen and (max-width: 768px) {
        padding-top: 45px;
    }
}*/

.actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

