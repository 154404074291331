
































































































































































































































@import "../../../../assets/scss/variables";

.modal {
    .btn-pas {
        margin-left: auto;
        margin-right: 0;
    }

    .modal-content {
        width: calc(100% - 60px) !important;
    }

    .cadre-content {
        display: flex;
        height: calc(100vh - #{$cadre-tete-height} - #{$cadre-pied-height} - 60px);

        > div {
            width: 50%;

            &:first-child {
                margin-right: 40px;
            }
        }
    }
    .long-checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            width: 80%;
        }
    }
}
