$gray-border: #E8E8E8;
@import "colors";

input:-webkit-autofill { -webkit-box-shadow:0 0 0 500px white inset; }

.form-control {
    border: 0;
    border-radius: 2px;
    height: 38px;
    padding: 10px;
    transition: all .2s;
    color: #444;
    outline: none;
    width: 100%;
    box-shadow: inset 0 0 0 1px $gray-border;
    background: #fff;
    box-sizing: border-box;

    &::placeholder {
        color: gray;
    }

    &:not(:disabled){
        &:active, &:focus, &:hover {
            color: $primary;
            box-shadow: inset 0 0 0 1px $primary;
        }
    }

    &:disabled {
        background: $gray-light;
        color: black;
    }

}

textarea.form-control {
    height: auto !important;
}

.form-group {
    margin-bottom: 20px;
    position: relative;

    &.form-group--error {
        input, select, textarea, .multiselect__tags {
            color: $error;
            box-shadow: 0 0 0 1px $error;
        }
    }

    .form-error {
        color: $error;
        font-size: .75rem;
        margin-top: 5px;
        position: absolute;
        bottom: -20px;
        right: 0;
    }

}

.form-cadre {
    background: $gray-lighter;
    border: 1px solid $gray-light;
    padding: 15px;
    margin-top: 15px;

    .cadre-titre {
        padding: 10px 0 15px;
        font-size: .9em;
        color: #777;
    }

    .icon-trash {
        width: 100%;
        text-align: right;
        color: $primary;
    }

}

.form-info {
    font-size: .7rem;
    font-weight: 500;
    margin-top: 5px;
    position: absolute;
}

label {
    display: block;
    margin-bottom: 10px;
}

input, select {
    width: 100%;
}

input, textarea {
    font-family: inherit;
}

.form-symbole {
    position: relative;
    height: 36px;

    > input {
      margin-top: -1px;
    }

    &::after {
        width: 30px;
        height: 100%;
        background: $gray-light;
        position: absolute;
        right: 1px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
    }

    &.form-pourcentage {
        &::after {
            content: '%';
        }
    }

    &.form-euros,
    &.form-euro {
        &::after {
            content: '€';
        }
    }

    &.form-FCFA {
        &::after {
            content: 'FCFA';
        }
    }

    &.form-chf {
        &::after {
            content: 'CHF';
            font-size: 10px;
        }
    }
}

.label-complement {
    font-size: .6rem;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    margin-top: -10px;
}

