@import "colors";

.form-group {
    .multiselect__option--highlight {
        background: #f9f9f9;
        color: $primary;
    }

    .multiselect {
        cursor: pointer;

        .multiselect__content-wrapper {
            z-index: 9 !important;
            width: auto;
        }

        .multiselect__input {
            font-size: 13px;
            padding: 0;
            margin: 0;

            &:active, &:focus {
                color: $primary;
            }

            &::placeholder {
                color: gray;
            }
        }

        .multiselect__tags {
            box-shadow: 0 0 0 1px $gray-border;
            border: 0;
            padding: 10px;
            border-radius: 2px;
            font-size: 11px;

            .multiselect__single,
            .multiselect__strong {
                padding-left: 0;
                margin-bottom: 0 !important;
            }
        }

        .multiselect__element {
            border-top: 1px solid #ddd !important;
        }

        &.multiselect--active {

            .multiselect__tags {
                box-shadow: 0 0 0 1px $primary;
            }
        }

        .multiselect__single {
            font-size: 13px;
        }

        &.no-multiline {
            .multiselect__single {
                display: none;
            }
        }

        strong {
            font-weight: 400;
            font-size: 13px !important;
            color: #555;
        }

        .option__desc {
            .fa-check-circle {
                color: #ddd;
                margin-right: 10px;

                &.active {
                    color: $primary;
                }
            }
        }
    }

    &.form-group--error {
        .multiselect__tags {
            box-shadow: 0 0 0 1px $error !important;
        }
    }

    .multiselect__placeholder {
        margin-bottom: 0 !important;
    }
}

