

















































































@import "../../assets/scss/colors";

   .title {
   position: relative;
   color: $primary;
   border-bottom: 1px solid $primary;
   padding: 15px 8px;
   text-align: center;
   }
   .cont {
       padding: 20px;
   overflow-y: scroll;
   overflow-x: unset;
   height: 100% !important;

   &.cadre-content-principal {
     padding: 10px;
   }
      
   }
   .cadre-folder {
       max-width: 400px;
       margin: left;
   }
   .menu-brique a {
       border-radius: 0;
   }

   .cadre-pied {
       margin-left: auto;
   }
   
   @media (max-width: 350px) {
    .piece-conteneur{
       flex-direction: column;
    }    
   }
   @media (min-width: 351px) {
    .piece-conteneur{
       flex-direction: row;
    }
   
   }
   .actions-container {
       display: flex;
       align-items: center;
       justify-content: flex-end;
       padding: 10px;
   } 
   .modalWebWiew {
       position:absolute;
       top:15px;
       width: 100%;
   }
   .fade-enter-active,
   .fade-leave-active {
       transition:  all  1s ease;
   }

   .fade-enter-from,
   .fade-leave-to {
   opacity: 0;
   
   }
   
        
