@import 'colors';

.notification-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 300px;
  padding: 20px;
  
  border-radius: 2px;
  color: white;
  text-align: center;

  &.success {
    background: $success;
  }

  &.warning {
    background: #f76300;
  }

  &.error {
    background: $error;
  }

}

.notification-afficher-enter-active, .notification-afficher-leave-active {
  transition: all 0s;
}

.notification-afficher-enter, .notification-afficher-leave-to {
  top: -100%;
}
