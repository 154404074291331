@import "colors";

.confirm {

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 500;
        top: 0;
        left: 0;
    }

    .confirm-container {
        position: absolute;
        min-width: 425px;
        min-height: 250px;
        background: white;
        z-index: 999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 425px){
          min-width: 100%;
        }
    }

    .confirm-content {
        display: flex;
        align-items: center;
        margin: auto 0;
        min-height: 200px;

        padding: 30px;
        line-height: 25pt;

        .icon-status {
            width: 10%;
            font-size: 30px;
        }
    }

    .confirm-footer {
        margin-top: auto;
        margin-bottom: 0;
        min-height: 50px;
        padding: 10px;

        display: flex;
        justify-content: space-between;

        .btn-ok {
            margin-right: 0;
            margin-left: auto;
        }
    }

    &.success {
        .confirm-container {
            border: 2px solid $success;

        }

        .confirm-footer {
            background: $success;
        }

        .icon-status {
            color: $success;
        }
    }

    &.error {
        .confirm-container {
            border: 2px solid $error;

        }

        .confirm-footer {
            background: $error;
        }

        .icon-status {
            color: $error;
        }
    }

    &.ask-confirm {
        .confirm-container {
            border: 2px solid $primary;

        }

        .confirm-footer {
            background: $primary;
        }

        .icon-status {
            color: $primary;
        }
    }
}
