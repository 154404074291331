@import "colors";

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-success {
    color: $success
}

.text-error {
    color: $error;
}

.text-muted {
  color: $gray;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: 500;
}

.background-primary {
  color: white !important;
  background: $primary;
}

.background-gray {
  background: $gray-lighter !important;
}

.background-white {
  background: white;
}

.list-style-none {
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

.no-margin {
    margin: 0;
}

.disabled {
    pointer-events: none;
    color: $gray-light;
}
