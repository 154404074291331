.dossier-client-app {

  /**
  *
  * FONT-AWESOME
  *
  */

  @import "~@fortawesome/fontawesome-free/css/all.css"; /**
  *
  * Vue Multiselect
  *
  */
  @import "~vue-multiselect/dist/vue-multiselect.min.css"; /**
  *
  * PROJECT
  *
  */
  @import "colors";
  @import "form";
  @import "button";
  @import "layout";
  @import "utils";
  @import "checkbox";
  @import "title";
  @import "menu";
  @import "notification";
  @import "modal";
  @import "animations";
  @import "confirm";
  @import "calendar";
  @import "multiselect";
  @import "switch";
  @import "badge";
  @import "table";
  @import "pace";
  @import "autocomplete";

  @import "navigation";
}

