

















@import "../assets/scss/colors";

.text-warning {
    color: #ff5722;
    padding: 30px;
    border: 1px solid #ff5722;
    margin-bottom: 20px;
}
