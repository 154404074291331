





































@import "../assets/scss/colors";

.kpis {
    list-style: none;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -10px 0;
    font-size: .8rem;
}

.kpi {
    width: 50%;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        color: $primary !important;
    }
}
