@import "colors";

.vdp-datepicker__calendar {
    .selected {
        background: $primary !important;
        color: white;
    }

    .cell {
        &:not(.blank){
            &:hover{
                border: 1px solid $primary !important;
            }
        }

    }

    .day-header {
        pointer-events: none;
    }
}

.vdp-datepicker__calendar-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
