@import "colors";

#navigation {
    
    .cadre {
        border: 0;
    }

    .cadre-content {
        padding-right: 0;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .etapes {
        position: relative;
        color: $black;
        list-style: none;
        margin: 0;
        padding: 0;

        &:before {
            content: '';
            width: 2px;
            height: 100%;
            background: $gray-light;
            position: absolute;
            left: 7px;
            top: 20px
        }
    }

    .etape {
        cursor: pointer;

        i {
            margin-right: 0;
            margin-left: auto;

            &.etape--complete {
                color: $primary;
            }

            &.etape--noncomplete {
                color: #e2e2e2;
            }
        }

        a {
            text-decoration: none;
            color: #888;
            display: flex;
            align-items: center;
            padding: 10px 10px 10px 30px;
            position: relative;

            .list-numero {
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: #ddd;
                left: 0;
                top: 0;
                transform: translateY(50%);
                font-size: .7rem;
                color: white;
                font-weight: bolder;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.router-link-active {

            a {
                color: $primary;

                .list-numero {
                    background: $primary;
                }
            }
        }
    }

    .etape.router-link-active + .menu-formulaires {
        max-height: 40vh;
    }

    .menu-formulaires {
        transition: all .15s;
        max-height: 0;
        overflow: auto;
        box-shadow: inset 0 0 0 1px $gray-border;
        margin-left: 7px;
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }

        .menu-formulaire {
            display: flex;
            i {
                margin-right: 0;
                margin-left: auto;
            }
        }

        ul {
            background: white;
            overflow: hidden;
            list-style: none;
            margin: 1px 0;
            padding: 0;
            width: 100%;

            li {
                margin-bottom: 0;

                a {
                    padding: 12px 10px 12px 30px;
                    text-decoration: none;
                    color: #555;
                    display: flex;
                    width: 100%;

                    &.router-link-active {
                        color: $primary;
                        background: #f5f5f5;
                    }
                }

            }
        }

    }

    .formulaire--complete {
        color: $success !important;
    }

    .formulaire--noncomplete {
        color: #e2e2e2 !important;
    }
}
