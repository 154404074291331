


































































































































































































@import "../assets/scss/_colors.scss";

.stella{
    margin-top: 10px;
   display:flex;
    height: 60px !important;
}
.load{
    display: flex;
    text-align: center;
    
}
.loader {
    border: 11px solid #f3f3f3;
    border-radius: 50%;
    border-top: 11px solid #3498db;
    width: 39px;
    height: 39px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.btn {
    min-width: 50px;
    margin-bottom: 5px;
    height: 46px !important;
    border-radius: 5px !important;
    padding: 0 20px;
    text-transform: none !important;
    color: $primary;
    cursor: pointer;
    transition: all 0.2s;
    }
.btn:hover {
    background-color: $primary;
    color: aliceblue;

}
.btn-file {
    position: relative;
    overflow: hidden;
    border: 1px solid $primary;
    background: transparent;
    display: inline-block;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 50px;
    margin-bottom: 5px;
    height: 40px;
   
    padding: 0 20px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
    width: -webkit-fill-available;
    
}

.align{
   display:flex;
   justify-content: center;
   gap:7px;
  
}
.icon-custom{
    color: #305ab5;
    font-size: 20px;
   
   
}
.icon-custom-cross{
    color: #ca2722;
    font-size: 20px;
    border:none;
    margin-right: 10px;
   
   
}
.cross{
    border: none;
    background: transparent;
}
 .icons:hover {

color: aliceblue;
}
.fade-enter-active,
.fade-leave-active {
    transition:  all  1s ease;
}

.fade-enter-from,
.fade-leave-to {
opacity: 0;

}
.select{
    // A reset of styles, including removing the default dropdown arrow
    // Additional resets for further consistency
    background-color: transparent;
   
    border-radius: 5px  !important;
    border: 5px solid #D5E8FA;
    padding: 0 1em 0 0;
    margin: 0;
    width: 285px !important;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
}
select::-ms-expand {
display: none;
}
.stella-bis{
    margin-left: 10px;
}

@media only screen and (max-width: 767px) {
    
    .loader{
        width: 24px;
        height: 22px;
    }
    
    
}

