@import "colors";

table {
    text-align: left;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 5px;
}

thead tr th {
    border-bottom: 1px solid $gray-light;
    border-collapse: separate;
    border-spacing: 5px 5px;
}
