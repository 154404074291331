











































































































































































































.edit-icon {
    margin-right: 0;
    margin-left: auto;
}
