@import "colors";


.menu-brique {
    margin-bottom: 15px;
    background: #fff;

    > div:first-child:not(.router-link-active) {
        box-shadow: 0 0 0 1px $gray-light;
    }

    .router-link-active {
        a {
            background: $primary;
            color: #fff;
        }
        
        box-shadow: 0 0 0 1px $primary;
    }

    a {
        padding: 15px;
        margin-bottom: 0;
        text-decoration: none;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .2s;

        > div {
            display: flex;
            align-items: center;
        }


        &:hover {
            background: $primary;
            color: white;

            .menu-contact {
                color: lighten($gray, 30%);
            }
        }
    }

    &.brique-creer {
        background: $primary;

        a {
            color: white;
            display: none;

            &.router-link-active {
                display: block;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    .avatar-icon {
        margin-right: 15px;
        font-size: 20px;
    }

    .infos {
        padding: 15px;
        box-shadow: inset 0 0 0 1px #E7E7E7;
    }

    .info-group {
        font-size: .9rem;
        label {
            font-size: .8rem;
            color: $gray;
        }
    }

    .menu-metric-title {
        font-size: .8rem;
        margin-bottom: 5px;
        font-weight: 100;
    }

    .menu-metrics {
        padding: 15px;
    }

    .menu-form-complet {
        font-size: 0.8rem;
        padding: 15px;

        &.menu-form-complet--complet {
            color: $success;
        }

        &.menu-form-complet--noncomplet {
            color: $gray;
        }
    }

    .menu-brique-tete {
    }

    .menu-brique-contenu {
        border-top: 1px solid #E7E7E7;

        ul {
            margin-bottom: 0;
        }
    }

    .menu-brique-pied {
        border-top: 1px solid #E7E7E7;
    }
}

.cadre-precision {
    background: white;
    border-top: 1px solid $gray-light;
    min-height: 135px;
    height: 135px;
    cursor: pointer;

    &.precision-simple{
        min-height: 125px;
        height: 125px;
    }

    &.router-link-active {
        box-shadow: inset 0 0 0 1px $primary;

        .precision-title {
            color: $primary;
        }
    }

    .precision-title {
        display: flex;
        width: 100%;
        height: 30px;
        padding: 15px;
    }

    .precision-content {
        padding: 15px;
        color: $gray;
        height: calc(100% - 30px);
        overflow: hidden;

        > div {
            margin-bottom: 10px;
        }
        //display: flex;
        //justify-content: space-between;
    }

    .edit-icon {
        margin-left: auto;
        margin-right: 0;
    }

    .form-group {
        flex-grow: 1;
        overflow: hidden;
        font-size: .9rem;
        label {
            font-size: .8rem;
            margin-bottom: 0;
        }
        margin-bottom: 10px;
    }
}

